<template>
  <div>
    <div class="flex items-center justify-between mb-20">
      <h1 class="font-medium text-lg px-16 sm:px-0">
        Advertiser Settings
      </h1>
      <el-button
        type="primary"
        :loading="buttonLoading"
        class="ml-auto sm:ml-24 mb-20 sm:mb-0 mr-16 sm:mr-0 order-1 sm:order-2"
        @click="$router.push({ name: 'AdminUsers' })"
      >
        Contact support
      </el-button>
    </div>

    <div class="flex flex-col sm:flex-row items-start">
      <el-card class="mb-32 px-16 sm:px-24 flex-1">
        <advertiser-settings
          :user-data="userData"
          :loading="buttonLoading"
          class="mb-20"
        />
      </el-card>
      <el-button
        type="primary"
        :loading="buttonLoading"
        class="ml-auto sm:ml-24 mb-20 sm:mb-0 mr-16 sm:mr-0 order-1 sm:order-2"
        @click="$router.push({ name: 'AdminUsers' })"
      >
        Back to user list
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserService from '@/services/user.service'

const AdvertiserSettings = () => import('@/components/admin-users/AdvertiserSettings')
const ElButton = () => import('@/components/shared/ELButton')
const ElCard = () => import('@/components/shared/ELCard')

export default {
  name: 'AdvertiserUserEditPage',

  components: { AdvertiserSettings, ElButton, ElCard },

  data () {
    return {
      buttonLoading: false,
      userData: {
        email: '',
        full_name: '',
        role: ''
      }
    }
  },

  created () {
    this.firstLoad()
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    firstLoad () {
      UserService.getAdminUserById(this.$route.params.id)
        .then(res => {
          res.sanity_data = JSON.parse(res.sanity_data)
          this.userData = res
          this.loading(false)
        })
    }
  }
}
</script>
